import React, { useEffect, useState } from 'react';
import { Component } from 'src/helpers/Component';
import { Headline } from 'src/helpers/Headline';
import {
  GetStaticComponentProps,
  useComponentProps,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextWrapper from 'src/helpers/RichTextWrapper/RichTextWrapper';
import {
  MediaPrimary,
  MediaPrimaryStaticProps,
  MediaSecondary,
  MediaSecondaryStaticProps,
  getMediaPrimaryStaticProps,
  getMediaSecondaryStaticProps,
} from 'src/helpers/Media';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
import { ContentBlockWithMediaTheme } from './ContentBlockWithMedia.theme';
import { useExperienceEditor } from 'lib/utils';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import { getEnum } from 'lib/utils';
import {
  ButtonVariants,
  cta1ToButtonProps,
  cta2ToButtonProps,
  cta3ToButtonProps,
} from 'src/helpers/Button';
import { ButtonGroup } from 'src/helpers/ButtonGroup';

export type ContentBlockWithMediaProps =
  Feature.EnterpriseWeb.Enterprise.Components.General.ContentBlockWithMedia.ContentBlockWithMedia;

const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const ContentBlockWithMedia = (props: ContentBlockWithMediaProps): JSX.Element => {
  const isEE = useExperienceEditor();
  const { themeData } = useTheme(
    ContentBlockWithMediaTheme(props, getEnum<ButtonVariants>(props.fields?.cta2Style) || 'link')
  );
  const { currentScreenWidth } = useCurrentScreenType();
  const componentProps = useComponentProps<MediaPrimaryStaticProps & MediaSecondaryStaticProps>(
    props.rendering.uid
  );
  const [inIframe, setInIframe] = useState(false);

  useEffect(() => {
    setInIframe(isInIframe());
  }, []);

  // Fail out if we don't have any fields
  if (!props) {
    return <></>;
  }

  const imageLayoutType =
    props.fields?.primaryImage?.value?.src &&
    !props.fields?.secondaryImage?.value?.src &&
    currentScreenWidth >= getBreakpoint('md')
      ? 'intrinsic'
      : 'responsive';

  const imageContainerWidth =
    imageLayoutType === 'intrinsic'
      ? { maxWidth: `${props.fields?.primaryImage?.value?.width}px` }
      : {};

  const focusArea = 'top center';

  return (
    <Component variant="lg" dataComponent="general/contentblockwithmedia" {...props}>
      <div
        className={
          inIframe
            ? 'col-span-12 mt-xl grid grid-cols-12 md:gap-x-s'
            : 'col-span-12 grid grid-cols-12 md:gap-x-s'
        }
      >
        <div className={themeData.classes.headingContainer}>
          <Headline defaultTag="h2" classes={themeData.classes.headlineContainer} {...props} />
          <RichTextWrapper
            field={props.fields?.topCopy}
            className={themeData.classes.topCopyContainer}
          />
        </div>
        <div className={themeData.classes.bodyContainer}>
          <div className={themeData.classes.imageContainer}>
            {(props.fields?.primaryImage?.value?.src || props.fields?.primaryVideo || isEE) && (
              <div className={themeData.classes.imageOuterContainer} style={imageContainerWidth}>
                <MediaPrimary
                  {...props}
                  imageLayout={imageLayoutType}
                  focusArea={focusArea}
                  staticProps={componentProps?.mediaPrimary}
                />
              </div>
            )}
            {(props.fields?.secondaryImage?.value?.src || props.fields?.secondaryVideo || isEE) && (
              <div className={themeData.classes.imageOuterContainer} style={imageContainerWidth}>
                <MediaSecondary
                  {...props}
                  imageLayout={imageLayoutType}
                  focusArea={focusArea}
                  staticProps={componentProps?.mediaSeconary}
                />
              </div>
            )}
          </div>
          <RichTextWrapper
            classes={themeData.classes.bodyContainer}
            field={props.fields?.bottomCopy}
          />
        </div>
        <div className={themeData.classes.contentWrapper}>
          <ButtonGroup
            cta1={cta1ToButtonProps(props, themeData.classes.buttonGroupClass.cta1Classes)}
            cta2={cta2ToButtonProps(props, themeData.classes.buttonGroupClass.cta2Classes)}
            cta3={cta3ToButtonProps(props, themeData.classes.buttonGroupClass.cta3Classes)}
            wrapperClasses={themeData.classes.buttonGroupClass.wrapper}
            ctaAlignment={props.fields?.ctaAlignment}
          />
        </div>
      </div>
    </Component>
  );
};

export const getStaticProps: GetStaticComponentProps = async (rendering) => {
  const datasource = rendering as ContentBlockWithMediaProps;

  const mediaStaticProps: MediaPrimaryStaticProps & MediaSecondaryStaticProps = {};

  if (!datasource) {
    return mediaStaticProps;
  }

  mediaStaticProps.mediaPrimary = await getMediaPrimaryStaticProps(datasource);
  mediaStaticProps.mediaSeconary = await getMediaSecondaryStaticProps(datasource);

  return mediaStaticProps;
};

export default withDatasourceCheck()<ContentBlockWithMediaProps>(ContentBlockWithMedia);
