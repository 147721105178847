// Global
import { useEffect, useState } from 'react';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { CookieValueTypes, getCookie } from 'cookies-next';
// Components
import { Component } from 'src/helpers/Component';
import classNames from 'classnames';
import { useAffiliate } from 'lib/context/AffiliateContext';

export type EnewsSignUpBannerProps =
  Feature.EnterpriseWeb.RenewalByAndersen.Components.Forms.EnewsSignUpBanner.EnewsSignUpBanner;
const EnewsSignUpBanner = (props: EnewsSignUpBannerProps) => {
  const { fields, rendering } = props;
  const { userAffiliate } = useAffiliate();

  const [isCookieAvailable, setIsCookieAvailable] = useState<CookieValueTypes>(false);

  //  Check if the cookie is available for this component
  const visibileCookieName = fields?.visibilityCookie?.fields?.cookieName?.value;

  // programOptIns value for EnewsletterSignup is true / false
  const showEnewsSignUpBanner = userAffiliate?.programOptIns?.EnewsletterSignup;

  useEffect(() => {
    setIsCookieAvailable(getCookie(visibileCookieName));
    // we can ignore this as 'visibileCookieName' coming from layout data this will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!rendering || isCookieAvailable) {
    return <></>;
  }

  return (
    <Component
      variant="full"
      dataComponent="forms/enewssignupbanner"
      id="enewssignupbanner"
      sectionWrapperClasses={classNames(
        getCookie(visibileCookieName) === true && 'hidden', // hide sticky banner when cookie is present
        !showEnewsSignUpBanner && 'hidden' // hide it if EnewsletterSignup is false for programOptIns
      )}
      {...props}
    >
      <div className="col-span-12 mx-[calc(50%-50vw)] flex min-h-[94px] items-center bg-light-gray p-m">
        <div className="mx-auto w-full max-w-screen-lg">
          <div className="flex ml:w-full ml:flex-row ml:justify-between">
            {/* Form Fields */}
            <div className="flex-grow [&_.pages_.grid]:!gap-x-xxxs">
              <Placeholder name="form" rendering={rendering} isHorizontalForm={true} />
            </div>
          </div>
        </div>
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<EnewsSignUpBannerProps>(EnewsSignUpBanner);
