import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import classNames from 'classnames';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//import { getEnum } from 'lib/utils';
//import { ButtonVariants, cta1ToButtonProps, cta2ToButtonProps } from 'src/helpers/Button';
import { cta1ToButtonProps, cta2ToButtonProps } from 'src/helpers/Button';
import { Headline } from 'src/helpers/Headline';
import { useTheme } from 'lib/context/ThemeContext';
import { HeroTwoColumnTheme } from './HeroTwoColumn.theme';
import { Component } from 'src/helpers/Component';
import { Subheadline } from 'src/helpers/Subheadline';
import { BodyCopy } from 'src/helpers/BodyCopy';
import { ButtonGroup } from 'src/helpers/ButtonGroup';

export type HeroTwoColumnProps = Feature.EnterpriseWeb.Enterprise.Components.Hero.HeroTwoColumn;

const HeroTwoColumn = (props: HeroTwoColumnProps) => {
  //const cta2Style = getEnum<ButtonVariants>(props.fields?.cta2Style) || 'link';
  //const { themeName, themeData } = useTheme(HeroTwoColumnTheme(cta2Style));
  const { themeName, themeData } = useTheme(HeroTwoColumnTheme);

  if (!props.fields) {
    return null;
  }

  return (
    <Component variant="lg" dataComponent="hero/herotwocolumn" {...props}>
      {themeName === 'rba' && (
        <div className="col-span-12 md:col-span-1">
          <span className="inline-block h-[3px] w-l bg-primary md:w-full"></span>
        </div>
      )}
      <div className={`col-span-12 ${themeName === 'rba' ? 'md:col-span-5' : 'md:col-span-6'}`}>
        <Headline useTag="h1" classes={themeData.classes.headlineClass} {...props} />
      </div>
      <div className="col-span-12 md:col-span-6">
        <Subheadline
          useTag="h2"
          classes={classNames(themeData.classes.subheadlineClass, {
            'mb-s': !props.fields?.body.value,
          })}
          {...props}
        />
        <BodyCopy classes={themeData.classes.bodyClass} {...props} />
        <ButtonGroup
          cta1={cta1ToButtonProps(props, themeData.classes.buttonGroupClass.cta1Classes)}
          cta2={cta2ToButtonProps(props, themeData.classes.buttonGroupClass.cta2Classes)}
          wrapperClasses={themeData.classes.buttonGroupClass.wrapper}
        />
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<HeroTwoColumnProps>(HeroTwoColumn);
