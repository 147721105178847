import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import classNames from 'classnames';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
// import { getEnum } from 'lib/utils';
// import { ButtonVariants, cta1ToButtonProps, cta2ToButtonProps } from 'src/helpers/Button';
import { cta1ToButtonProps, cta2ToButtonProps } from 'src/helpers/Button';
import { Headline } from 'src/helpers/Headline';
import { Component } from 'src/helpers/Component';
import { TwoColumnHeadlineTheme } from './TwoColumnHeadline.theme';
import { useTheme } from 'lib/context/ThemeContext';
import { Subheadline } from 'src/helpers/Subheadline';
import { BodyCopy } from 'src/helpers/BodyCopy';
import { ButtonGroup } from 'src/helpers/ButtonGroup';

export type TwoColumnHeadlineProps =
  Feature.EnterpriseWeb.Enterprise.Components.General.TwoColumnHeadline.TwoColumnHeadline;

const TwoColumnHeadline = (props: TwoColumnHeadlineProps) => {
  // const cta2Style = getEnum<ButtonVariants>(props.fields?.cta2Style) || 'link';
  // const { themeData } = useTheme(TwoColumnHeadlineTheme(cta2Style));
  const { themeData } = useTheme(TwoColumnHeadlineTheme);

  if (!props.fields) {
    return null;
  }

  return (
    <Component variant="lg" dataComponent="general/twocolumnheadline" {...props}>
      <div className="col-span-12 md:col-span-6">
        <Headline classes={themeData.classes.headlineClass} {...props} />
      </div>
      <div className="col-span-12 md:col-span-6">
        <Subheadline
          classes={classNames(themeData.classes.subheadlineClass, {
            'mb-s': !props.fields?.body.value,
          })}
          {...props}
        />
        <BodyCopy classes={themeData.classes.bodyClass} {...props} />
        <ButtonGroup
          cta1={cta1ToButtonProps(props, themeData.classes.buttonGroupClass.cta1Classes)}
          cta2={cta2ToButtonProps(props, themeData.classes.buttonGroupClass.cta2Classes)}
          wrapperClasses={themeData.classes.buttonGroupClass.wrapper}
        />
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<TwoColumnHeadlineProps>(TwoColumnHeadline);
