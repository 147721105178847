import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { DesktopVideoDisplayStyleType } from 'components/listing/XupCardCollection/XupCardCollection';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import React, { useState } from 'react';
import Head from 'next/head';
import { Image, MediaPrimary, MediaStaticProps } from 'src/helpers/Media';
import { ImagePrimaryProps } from 'src/helpers/Media/ImagePrimary';
import { getSourceNameFromVideoItem, playStopVideo } from 'src/helpers/Media/VideoUtils';
import ModalWrapper from 'src/helpers/ModalWrapper/ModalWrapper';
import ReactDOM from 'react-dom';
import { useTheme } from 'lib/context/ThemeContext';
import { SvgIcon } from 'src/helpers/SvgIcon';
import { Foundation } from 'src/.generated/Foundation.EnterpriseWeb.model';

import { SitecoreIds } from 'lib/constants';
import { guidEquals } from 'lib/utils/string-utils';
import { normalizeSitecoreDateStringFormattedWithTime } from 'lib/utils/string-utils';

type VideoCardProps = ImagePrimaryProps &
  Foundation.EnterpriseWeb.Enterprise.FieldSets.VideoPrimary & {
    fields?: { videoThumbnailImage?: ImageField };
    desktopVideoDisplayStyle: DesktopVideoDisplayStyleType;
    staticProps?: MediaStaticProps;
  };

const VideoCard = ({ desktopVideoDisplayStyle, ...props }: VideoCardProps) => {
  const { currentScreenWidth } = useCurrentScreenType();

  const { themeName } = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModalAndPlayVideo = () => {
    const primaryVideo = props.fields?.primaryVideo;
    setIsModalOpen(true);
    playStopVideo(
      (primaryVideo?.fields?.videoId as Field<string>)?.value || '',
      'playVideo',
      primaryVideo && getSourceNameFromVideoItem(primaryVideo)
    );
  };
  // Video Schema
  let ldJsonScriptYouTube;
  let ldJsonScriptVimeo;
  if (
    guidEquals(
      props?.fields?.primaryVideo?.templateId,
      SitecoreIds.Feature.Data.Elements.Videos.YouTubeVideo.TemplateId
    )
  ) {
    const formattedDate =
      (props.fields?.primaryVideo?.fields?.lastUpdated as Field<string>)?.value &&
      (props.fields?.primaryVideo?.fields?.lastUpdated as Field<string>)?.value !=
        '0001-01-01T00:00:00Z' &&
      normalizeSitecoreDateStringFormattedWithTime(
        (props.fields?.primaryVideo?.fields?.lastUpdated as Field<string>)?.value
      );

    ldJsonScriptYouTube = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      contentURL: props.fields?.primaryVideo?.fields?.videoId
        ? `https://www.youtube.com/watch?v=${
            (props.fields?.primaryVideo?.fields?.videoId as Field<string>).value
          }`
        : '',
      description:
        (props.fields?.primaryVideo?.fields?.videoDescription as Field<string>)?.value || '',
      embedUrl: props.fields?.primaryVideo?.fields?.videoId
        ? `https://www.youtube.com/embed/${
            (props.fields?.primaryVideo?.fields?.videoId as Field<string>)?.value
          }`
        : '',
      name: (props.fields?.primaryVideo?.fields?.videoName as Field<string>)?.value || '',
      thumbnailUrl:
        props.fields?.videoThumbnailImage?.value?.src ||
        `https://i.ytimg.com/vi/${
          (props.fields?.primaryVideo?.fields?.videoId as Field<string>).value
        }/sddefault.jpg` ||
        '',
      uploadDate: formattedDate || '',
    };
  }

  if (
    guidEquals(
      props?.fields?.primaryVideo?.templateId,
      SitecoreIds.Feature.Data.Elements.Videos.VimeoVideo.TemplateId
    )
  ) {
    const formattedDate =
      (props.fields?.primaryVideo?.fields?.lastUpdated as Field<string>)?.value &&
      (props.fields?.primaryVideo?.fields?.lastUpdated as Field<string>)?.value !=
        '0001-01-01T00:00:00Z' &&
      normalizeSitecoreDateStringFormattedWithTime(
        (props.fields?.primaryVideo?.fields?.lastUpdated as Field<string>)?.value
      );
    ldJsonScriptVimeo = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      contentURL: `https://vimeo.com/${
        (props.fields?.primaryVideo?.fields?.videoId as Field<string>).value
      }`,
      description:
        (props.fields?.primaryVideo?.fields?.videoDescription as Field<string>)?.value || '',
      embedUrl: `https://player.vimeo.com/video/${
        (props.fields?.primaryVideo?.fields?.videoId as Field<string>).value
      }`,
      name: (props.fields?.primaryVideo?.fields?.videoName as Field<string>)?.value || '',
      thumbnailUrl:
        props.fields?.videoThumbnailImage?.value?.src ||
        props.staticProps?.videoStaticProps?.vimeoStaticProps?.videoThumbnailUrl ||
        '',
      uploadDate: formattedDate || '',
    };
  }
  if (desktopVideoDisplayStyle === 'in-line' && currentScreenWidth > getBreakpoint('md')) {
    return (
      <>
        <Head>
          {ldJsonScriptYouTube && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonScriptYouTube) }}
            />
          )}
          {ldJsonScriptVimeo && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonScriptVimeo) }}
            />
          )}
        </Head>
        <MediaPrimary
          {...props}
          videoThumbnailImage={props?.fields?.videoThumbnailImage}
          staticProps={props.staticProps}
        />
      </>
    );
  }

  if (desktopVideoDisplayStyle === 'in-modal' || currentScreenWidth <= getBreakpoint('md')) {
    return (
      <>
        <Head>
          {ldJsonScriptYouTube && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonScriptYouTube) }}
            />
          )}
          {ldJsonScriptVimeo && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonScriptVimeo) }}
            />
          )}
        </Head>
        <div className="relative">
          {props.fields?.videoThumbnailImage?.value?.src && (
            <>
              <span
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.code === 'Enter' || e.code === 'Space') {
                    openModalAndPlayVideo();
                  }
                }}
                onClick={() => openModalAndPlayVideo()}
                className="absolute top-1/2 left-1/2 z-[10] -translate-x-1/2 -translate-y-1/2 cursor-pointer"
              >
                <SvgIcon icon="play" size={currentScreenWidth > getBreakpoint('md') ? 80 : 91} />
              </span>
              <Image image={props.fields?.videoThumbnailImage} layout="responsive" />
            </>
          )}
        </div>
        {isModalOpen &&
          ReactDOM.createPortal(
            <ModalWrapper
              size="w-full md:w-[65vw] md:max-w-[1199px] h-auto max-h-[540px] md:max-h-[753px]"
              isModalOpen={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
            >
              <div className="p-m">
                <MediaPrimary {...props} />
              </div>
            </ModalWrapper>,
            document.querySelector(`.${themeName}`) || document.body
          )}
      </>
    );
  }

  return <></>;
};

export default VideoCard;
